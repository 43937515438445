import React from "react"
import Layout from "../components/layout"
import styles from "./404.module.scss"
import SEO from "../components/seo"

const NotFound = () => {
  return (
    <Layout>
      <SEO />
      <div className={styles.container}>
        <h1>404: Page Not Found!</h1>
        <div>
          <img src="/img/covers/404.jpg" alt="" />
        </div>
        <p>
          I probably broke a link
          <span role="img" aria-label="sweat smile">
            {" "}
            😅
          </span>
        </p>
      </div>
    </Layout>
  )
}

export default NotFound
